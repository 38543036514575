




















































import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import { useUserStore } from '@/pinia/modules/user'
import LayoutHeader from '@/components/LayoutHeader.vue'
import { Form, FormItem, Upload } from 'element-ui'
import { storeToRefs } from 'pinia'
import Avatar from '@/components/Avatar.vue'
import { uploadHandle, imageTypes } from '@/utils/upload'
import { showError } from '@/utils/common'
import { uploadImage } from '@/api/common'
import { editUserApi } from '@/api/user/index'

export default defineComponent({
    components: {
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [Upload.name]: Upload,
        LayoutHeader,
        Avatar
    },
    setup(props, { root }) {
        const userStore = useUserStore()
        const { userInfo } = storeToRefs(userStore)
        const formRef = ref()
        const formInfo: any = reactive({
            photo: userInfo.value.photo,
            email: userInfo.value.email,
            nickname: userInfo.value.nickname
        })
        const formRules = reactive({
            email: [
                { required: true, message: root.$t('accountEdit.formRule1'), trigger: 'blur' },
                { type: 'email', message: root.$t('accountEdit.formRule2'), trigger: ['blur', 'change'] }
            ],
            nickname: [{ required: true, message: root.$t('accountEdit.formRule3'), trigger: 'blur' }]
        })

        const photoUploadInfo = reactive({
            loading: false,
            isSuccess: false,
            before: (file: File) => {
                return uploadHandle(file, 1)
            },
            request: async (value: any) => {
                photoUploadInfo.loading = true
                photoUploadInfo.isSuccess = false
                try {
                    const { url } = await uploadImage({
                        file: value.file
                    })
                    formInfo.photo = url
                } catch (error) {
                    showError(error)
                }
                photoUploadInfo.loading = false
            }
        })

        const submitInfo = reactive({
            isSubmitLoad: false,
            isDisabled: computed(() => {
                return !Object.keys(formRules).every((key) => formInfo[key])
            }),
            onSubmitFrom: () => {
                formRef.value.validate().then(async () => {
                    submitInfo.isSubmitLoad = true
                    try {
                        const option = { ...formInfo }
                        await editUserApi(option)
                        await userStore.updateUserInfo()
                        root.$message.success(root.$t('saveSuccess').toString())
                    } catch (error) {
                        showError(error)
                    }
                    submitInfo.isSubmitLoad = false
                })
            }
        })

        return {
            formRef,
            formInfo,
            formRules,
            imageTypes,
            photoUploadInfo,
            submitInfo,
            userInfo
        }
    }
})
